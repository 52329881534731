import React from 'react';
import { Autocomplete, Chip, TextField } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';

import { EnumTypeForList } from '@interfaces';
import { typography } from '@theme';
import snakeCase from 'lodash/snakeCase';

interface AutocompleteInterface {
  field?: EnumTypeForList[];
  setField: (value: EnumTypeForList[]) => void;
  label?: string;
  options: EnumTypeForList[];
  sx?: SxProps<Theme>;
  required?: boolean;
  inputProps?: object;
  isOptionEqualToValue?: (option: EnumTypeForList, value: EnumTypeForList) => boolean;
  optionsLoading?: boolean;
}

const CustomMultiAutocompleteWithTags = ({
  field,
  setField,
  label,
  options,
  required = false,
  inputProps,
  optionsLoading,
  ...props
}: AutocompleteInterface) => (
  <Autocomplete
    size="small"
    multiple
    options={options}
    id={label}
    value={field}
    onChange={(_: any, newValue: EnumTypeForList[]) => setField(newValue)}
    loading={optionsLoading}
    renderTags={(value: readonly EnumTypeForList[], getTagProps) =>
      value.map((option: EnumTypeForList, index: number) => (
        <Chip
          variant="outlined"
          label={option.name_display}
          sx={{
            '& .MuiChip-label': {
              ...typography.body3,
              letterSpacing: 'unset',
            },
            border: 'none',
          }}
          {...getTagProps({ index })}
        />
      ))
    }
    renderInput={(params) => (
      <TextField
        {...params}
        label={label}
        inputProps={{
          ...params.inputProps,
          ...inputProps,
        }}
        required={required}
        size="small"
      />
    )}
    renderOption={(props, option) => (
      <li {...props} data-cy={`dropdown_option_${snakeCase(option.name_display)}`}>
        {option.name_display}
      </li>
    )}
    getOptionLabel={(option: EnumTypeForList) => option?.name_display}
    sx={{ margin: 0, width: '100%' }}
    {...props}
  />
);

export default CustomMultiAutocompleteWithTags;
