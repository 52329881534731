import React from 'react';
import { Grid, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { LoadingSkeleton, ProjectCard, ServiceMessage } from '@components';
import { ComponentProps } from './interface';
import { NoProjectsImage } from '@svgAsComponents';

const ProjectCardsListV2 = ({ projects, isLoading, isError }: ComponentProps) => {
  const largeMediaQuery = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  return (
    <>
      {isError && <ServiceMessage text="projects" />}
      {isLoading && (
        <Grid container spacing={2}>
          {[...Array(largeMediaQuery ? 3 : 2)].map((_, index) => (
            <Grid item key={index} xs={12} sm={12} md={6} lg={4}>
              <LoadingSkeleton type="overviewBlock" />
            </Grid>
          ))}
        </Grid>
      )}
      {!isLoading && !isError && !projects?.length && (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ flexGrow: 1, backgroundColor: 'white' }}
        >
          <NoProjectsImage />
          <Typography>There are no projects yet in this category</Typography>
        </Stack>
      )}
      {!isLoading && projects?.length && (
        <Grid container spacing={2}>
          {projects.map((item) => (
            <Grid item key={item.id} xs={12} sm={12} md={6} lg={4}>
              <ProjectCard item={item} link={'/projects'} />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

export default ProjectCardsListV2;
