import { useState, SyntheticEvent } from 'react';
import { useQuery } from 'react-query';

import { getCommentTypes } from '@globalService';
import { ICommentType, QueryNamesEnums } from '@interfaces';
import { ACTIVE_COMMENTS_TYPES } from '@constants';

export interface ControllerInterface {
  selectedValues: string[];
  types: ICommentType;
  values: string[];
  handleSelectChange: (event: SyntheticEvent<Element, Event>, value: string[]) => void;
}

export const useCommentTypes = (onChange): ControllerInterface => {
  const types = useQuery<ICommentType, Error>(
    [QueryNamesEnums.GET_COMMENT_TYPES],
    getCommentTypes.bind(this),
  );

  const [selectedValues, setSelectedValues] = useState([]);

  const handleSelectChange = (_event, value) => {
    setSelectedValues(value);
    onChange(value);
  };

  return {
    selectedValues,
    types: types?.data,
    values: Object.keys(types?.data || []).filter((o) => ACTIVE_COMMENTS_TYPES.includes(o)),
    handleSelectChange,
  };
};
