import React, { useState, useCallback, useMemo } from 'react';
import { TextField, StandardTextFieldProps } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import { typography } from '@theme';
import { CurrencyInput, PercentsInput } from '@components';
import { StringFieldModel } from '@models';
interface CustomInputInterface extends StandardTextFieldProps {
  field: StringFieldModel;
  label?: string;
  validationText?: string;
  inputProps?: object;
  sx?: SxProps<Theme>;
  labelId?: string;
  clearOnTouch?: boolean;
}
export const CustomTextField = ({
  field,
  label,
  validationText,
  clearOnTouch = false,
  onChange,
  ...props
}: CustomInputInterface) => {
  // the purpose of this workaround is clear the field on first touch from default value ('0' for example)
  // and at the same time not to show error message on this first clearing, only on the next validation
  const [isFirstChange, setIsFirstChange] = useState(true);
  const [isTouched, setTouched] = useState(false);

  const handleClick = () => {
    setTouched(true);
    if (clearOnTouch && isFirstChange) {
      field.setValue('');
    }
  };

  const handleOnChange = useCallback(
    (event) => {
      if (isFirstChange) {
        setIsFirstChange(false);
      }
      onChange ? onChange(event) : field.handleChange(event);
    },
    [isFirstChange, onChange],
  );

  // Determine whether to show the error
  const showError = useMemo(
    () => !field.isValid && (clearOnTouch ? !isTouched || !isFirstChange : true),
    [field.isValid, isFirstChange, isTouched, clearOnTouch],
  );

  const helperText = showError ? (!field.value ? `${label} is required` : validationText) : '';

  return (
    <TextField
      fullWidth
      variant="outlined"
      value={field.value}
      error={showError}
      helperText={helperText}
      label={label}
      onChange={handleOnChange}
      onClick={handleClick}
      size="small"
      {...props}
    />
  );
};

export const CustomCurrencyTextField = ({ inputProps, ...props }: CustomInputInterface) => (
  <CustomTextField
    inputProps={{
      style: { textAlign: 'left', ...typography.body3 },
      ...inputProps,
    }}
    InputProps={{
      inputComponent: CurrencyInput as any,
    }}
    {...props}
  />
);
export const CustomPercentTextField = ({ inputProps, ...props }: CustomInputInterface) => (
  <CustomTextField
    inputProps={{
      step: '.01',
      min: '0',
      max: '100',
      style: { textAlign: 'left', ...typography.body3 },
      ...inputProps,
    }}
    InputProps={{
      inputComponent: PercentsInput as any,
    }}
    {...props}
  />
);
