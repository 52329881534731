import React from 'react';
import { Box, FormControlLabel, Grid, Stack, Switch, Typography } from '@mui/material';

import {
  CustomTextField,
  InspectionInputsBlock,
  StyledBox,
  TabWrapperWithLeaveWarning,
} from '@components';
import { typography } from '@theme';
import * as Controller from './controller';

const InspectionsSettings = ({ project, hasInspectionsEditPermission }) => {
  const controller = Controller.useInspectionSettings({ project });
  const {
    inspectionFields,
    handleSubmitClick,
    isSubmitting,
    isUpdated,
    exitPath,
    isOrderAutomatically,
    setIsOrderAutomatically,
    isSubmitDisabled,
    isCurrentProjectArchived,
  } = controller;

  return (
    <TabWrapperWithLeaveWarning
      onSave={handleSubmitClick}
      isUpdated={isUpdated}
      isDisabled={isSubmitDisabled}
      tabValue="inspections"
      path={exitPath}
      isSubmitting={isSubmitting}
      currentPagePathname={'/projects/*/settings/inspections'}
    >
      <StyledBox>
        <Typography variant="h3">General</Typography>
        <Box sx={{ mt: 4 }}>
          <Typography variant="body2" sx={{ mb: 3 }}>
            At least one contact with phone number (primary or additional) is required.
          </Typography>
          <InspectionInputsBlock
            source="project__settings__inspections"
            disabled={!hasInspectionsEditPermission || isCurrentProjectArchived}
            {...inspectionFields}
            inputWidth={4}
            showAdditionalInfo={false}
          />
        </Box>
      </StyledBox>
      <StyledBox>
        <Stack>
          <Typography variant="h3">Automated ordering</Typography>
          <FormControlLabel
            sx={{
              mt: 2,
              '& .MuiTypography-root': {
                ...typography.body3,
              },
            }}
            control={
              <Switch
                sx={{ mr: '15px' }}
                checked={isOrderAutomatically}
                onChange={(event) => setIsOrderAutomatically(event.target.checked)}
                data-cy="project__settings__inspections__is_order_automatic__switch"
                disabled={!hasInspectionsEditPermission || isCurrentProjectArchived}
              />
            }
            label="Automatically order inspection on draw request submission"
          />
          <Typography variant="body3" sx={{ mt: 2, mb: 1 }}>
            Set the interval to automatically order inspections based on schedule and prompt
            borrower when complete.
          </Typography>
          <Grid container rowSpacing={4} columnSpacing={6}>
            <Grid item xs={12} sm={4}>
              <CustomTextField
                label="Inspection interval (in days)"
                field={inspectionFields.inspectionCadence}
                inputProps={{
                  'data-cy': `project__settings__inspections__inspection_cadence__input`,
                }}
                disabled={!hasInspectionsEditPermission || isCurrentProjectArchived}
              />
            </Grid>
          </Grid>
        </Stack>
      </StyledBox>
    </TabWrapperWithLeaveWarning>
  );
};

export default InspectionsSettings;
