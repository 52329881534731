import React, { FC } from 'react';
import { Popup } from '@components';
import {
  Button,
  Checkbox,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import * as Controller from './controller';
import { useParams } from 'react-router-dom';
import { ICoordinator } from '@interfaces';
import { LinkIcon } from '@svgAsComponents';

const AssignCoordinatorPopup: FC<{
  open: boolean;
  onClose: () => void;
  initCoordinator: ICoordinator;
  requestId: string;
  prId?: string;
}> = ({ open, onClose, initCoordinator, requestId, prId }) => {
  const { projectId } = useParams();
  const {
    users,
    onAssignCoordinator,
    handleChange,
    coordinatorId,
    shouldUpdateProjectCoordinator,
    setUpdateProjectCoordinator,
  } = Controller.useAssignCoordinatorPopup(initCoordinator, prId || projectId, requestId, onClose);

  return (
    <Popup open={open} maxWidth="md" title="Assign coordinator" icon={LinkIcon}>
      <Stack sx={{ width: '100%' }}>
        <InputLabel id="coordinator-select-label">Coordinator</InputLabel>
        {Boolean(users.length) && (
          <Select
            labelId="coordinator-select-label"
            id="coordinator-select"
            value={coordinatorId}
            renderValue={(value) => users.find((x) => x.id === value)?.full_name || 'Not assigned'}
            displayEmpty
            onChange={handleChange}
            data-cy="draw_switch_coordinator_select"
          >
            <MenuItem value="">Not assigned</MenuItem>
            {users.map((item) => (
              <MenuItem value={item.id} key={item.id}>
                {item.full_name}
              </MenuItem>
            ))}
          </Select>
        )}
        <FormControlLabel
          control={
            <Checkbox
              checked={shouldUpdateProjectCoordinator}
              onChange={(_e, value) => setUpdateProjectCoordinator(value)}
            />
          }
          label={
            <Typography variant="body2">
              Link this coordinator to the next requests as well.
            </Typography>
          }
        />

        <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ mt: '3.5rem' }}>
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
          <Button
            sx={{ ml: '0.5rem' }}
            onClick={onAssignCoordinator}
            data-cy="draw_switch_cooridnator_save_button"
          >
            Assign
          </Button>
        </Stack>
      </Stack>
    </Popup>
  );
};

export default AssignCoordinatorPopup;
