import React, { FC, PropsWithChildren } from 'react';
import { default as MuiStepper } from '@mui/material/Stepper';
import { stepConnectorClasses } from '@mui/material/StepConnector';
import { Step, StepLabel, Stack, Grid } from '@mui/material';

import { colors, fonts } from '@theme';
import { ComponentProps } from './interface';

const Stepper: FC<PropsWithChildren<ComponentProps>> = ({
  steps,
  children,
  activeStep = 0,
  buttons,
}) => {
  return (
    <Stack direction="column" sx={{ p: 3, flex: 1, width: '100%' }}>
      <MuiStepper
        activeStep={activeStep}
        sx={{
          [`& .${stepConnectorClasses.line}`]: {
            borderColor: colors.neutral.lighter,
          },
        }}
      >
        {steps.map((label, index) => (
          <Step key={label} completed={false}>
            <Grid
              sx={{
                px: 2,
                py: 1,
                backgroundColor: activeStep === index ? colors.background.lightest : '',
              }}
            >
              <StepLabel
                sx={{
                  '& .MuiSvgIcon-root': {
                    '&.Mui-active': {
                      color: colors.background.white,
                      '& .MuiStepIcon-text': {
                        fill: colors.main.primary.main,
                      },
                    },
                    '& .MuiStepIcon-text': {
                      fill: activeStep > index ? colors.background.white : colors.neutral.dark,
                      fontWeight: 600,
                      fontFamily: fonts.primary,
                    },
                    color: activeStep >= index ? colors.main.primary.main : colors.neutral.lightest,
                  },
                  '& .MuiStepLabel-label': {
                    color: activeStep >= index ? colors.main.primary.main : colors.neutral.dark,
                    fontWeight: 600,
                    fontFamily: fonts.primary,
                  },
                }}
              >
                {label}
              </StepLabel>
            </Grid>
          </Step>
        ))}
      </MuiStepper>
      {children}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        sx={{ height: '70px', position: 'relative' }}
      >
        {buttons}
      </Stack>
    </Stack>
  );
};

export default Stepper;
