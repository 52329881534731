export const states = [
  {
    name_display: 'AL, Alabama',
    name: 'AL',
  },
  {
    name_display: 'AK, Alaska',
    name: 'AK',
  },
  {
    name_display: 'AS, American samoa',
    name: 'AS',
  },
  {
    name_display: 'AZ, Arizona',
    name: 'AZ',
  },
  {
    name_display: 'AR, Arkansas',
    name: 'AR',
  },
  {
    name_display: 'CA, California',
    name: 'CA',
  },
  {
    name_display: 'CO, Colorado',
    name: 'CO',
  },
  {
    name_display: 'CT, Connecticut',
    name: 'CT',
  },
  {
    name_display: 'DE, Delaware',
    name: 'DE',
  },
  {
    name_display: 'DC, District of columbia',
    name: 'DC',
  },
  {
    name_display: 'FM, Federated states of micronesia',
    name: 'FM',
  },
  {
    name_display: 'FL, Florida',
    name: 'FL',
  },
  {
    name_display: 'GA, Georgia',
    name: 'GA',
  },
  {
    name_display: 'GU, Guam',
    name: 'GU',
  },
  {
    name_display: 'HI, Hawaii',
    name: 'HI',
  },
  {
    name_display: 'ID, Idaho',
    name: 'ID',
  },
  {
    name_display: 'IL, Illinois',
    name: 'IL',
  },
  {
    name_display: 'IN, Indiana',
    name: 'IN',
  },
  {
    name_display: 'IA, Iowa',
    name: 'IA',
  },
  {
    name_display: 'KS, Kansas',
    name: 'KS',
  },
  {
    name_display: 'KY, Kentucky',
    name: 'KY',
  },
  {
    name_display: 'LA, Louisiana',
    name: 'LA',
  },
  {
    name_display: 'ME, Maine',
    name: 'ME',
  },
  {
    name_display: 'MH, Marshall islands',
    name: 'MH',
  },
  {
    name_display: 'MD, Maryland',
    name: 'MD',
  },
  {
    name_display: 'MA, Massachusetts',
    name: 'MA',
  },
  {
    name_display: 'MI, Michigan',
    name: 'MI',
  },
  {
    name_display: 'MN, Minnesota',
    name: 'MN',
  },
  {
    name_display: 'MS, Mississippi',
    name: 'MS',
  },
  {
    name_display: 'MO, Missouri',
    name: 'MO',
  },
  {
    name_display: 'MT, Montana',
    name: 'MT',
  },
  {
    name_display: 'NE, Nebraska',
    name: 'NE',
  },
  {
    name_display: 'NV, Nevada',
    name: 'NV',
  },
  {
    name_display: 'NH, New hampshire',
    name: 'NH',
  },
  {
    name_display: 'NJ, New jersey',
    name: 'NJ',
  },
  {
    name_display: 'NM, New mexico',
    name: 'NM',
  },
  {
    name_display: 'NY, New york',
    name: 'NY',
  },
  {
    name_display: 'NC, North carolina',
    name: 'NC',
  },
  {
    name_display: 'ND, North dakota',
    name: 'ND',
  },
  {
    name_display: 'MP, Northern mariana islands',
    name: 'MP',
  },
  {
    name_display: 'OH, Ohio',
    name: 'OH',
  },
  {
    name_display: 'OK, Oklahoma',
    name: 'OK',
  },
  {
    name_display: 'OR, Oregon',
    name: 'OR',
  },
  {
    name_display: 'PW, Palau',
    name: 'PW',
  },
  {
    name_display: 'PA, Pennsylvania',
    name: 'PA',
  },
  {
    name_display: 'PR, Puerto rico',
    name: 'PR',
  },
  {
    name_display: 'RI, Rhode island',
    name: 'RI',
  },
  {
    name_display: 'SC, South carolina',
    name: 'SC',
  },
  {
    name_display: 'SD, South dakota',
    name: 'SD',
  },
  {
    name_display: 'TN, Tennessee',
    name: 'TN',
  },
  {
    name_display: 'TX, Texas',
    name: 'TX',
  },
  {
    name_display: 'UT, Utah',
    name: 'UT',
  },
  {
    name_display: 'VT, Vermont',
    name: 'VT',
  },
  {
    name_display: 'VI, Virgin islands',
    name: 'VI',
  },
  {
    name_display: 'VA, Virginia',
    name: 'VA',
  },
  {
    name_display: 'WA, Washington',
    name: 'WA',
  },
  {
    name_display: 'WV, West virginia',
    name: 'WV',
  },
  {
    name_display: 'WI, Wisconsin',
    name: 'WI',
  },
  {
    name_display: 'WY, Wyoming',
    name: 'WY',
  },
];
