import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Grid, CircularProgress } from '@mui/material';

import { ComponentProps } from './interface';

const PREFIX = 'Loader';

const classes = {
  root: `${PREFIX}-root`,
  text: `${PREFIX}-text`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    height: '100%',
  },

  [`& .${classes.text}`]: {
    fontFamily: 'Poppins',
    fontSize: 16,
    marginTop: theme.spacing(3),
  },
}));

const Loader = ({ text }: ComponentProps) => {
  return (
    <StyledGrid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      className={classes.root}
    >
      <Typography align="center" component="h1" color="inherit" className={classes.text}>
        {text}
      </Typography>
      <br />
      <CircularProgress size={40} />
    </StyledGrid>
  );
};

export default Loader;
