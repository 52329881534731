import { useState } from 'react';
import { EditPaymentConfigurationTypeInterface, PaymentConfiguration } from '@interfaces';

export const useEditPaymentConfigurationType = (): EditPaymentConfigurationTypeInterface => {
  const [configurationType, setConfigurationType] = useState(PaymentConfiguration.SEQUENTIAL);

  const configurationTypeOptions = [
    {
      value: PaymentConfiguration.SEQUENTIAL,
      label: 'Project sequential (Borrower equity first)',
    },
    {
      value: PaymentConfiguration.PER_DRAW_REQUEST,
      label: 'Partial per request (Loan to equity split at each disbursement)',
    },
  ];

  const handleConfigurationTypeChange = (event) => {
    setConfigurationType(event.target.value);
  };

  return {
    configurationType,
    configurationTypeOptions,
    handleConfigurationTypeChange,
    setConfigurationType,
  };
};
