export const user = () => 'users/me/';

export const refreshTokenPath = () => 'users/token/auth/refresh/';

export const logoutPath = () => 'users/token/auth/logout/';

export const loginPath = () => 'users/login/';

export const mfaStatusPath = () => 'users/login/mfa/status/';

export const createPasswordPath = () => 'users/me/password/setup/';

export const forgotPasswordPath = () => 'users/me/password/reset/';

export const resetPasswordPath = () => 'users/me/password/reset/confirm/';

export const companyUserPath = (companyId = 'company_pk', userId = 'user_pk') =>
  `companies/${companyId}/users/${userId}/`;

export const sendInvitationPath = (userId = 'user_pk') => `users/${userId}/invitations/send/`;

export const cancelInvitationPath = (userId = 'user_pk') => `users/${userId}/invitations/cancel/`;

export const projectCompanies = (projectId = 'project_pk') => `projects/${projectId}/companies/`;

export const notifications = () => 'users/me/settings/notifications/';
export const resendInvitePath = () => 'users/me/resend-invite/';
export const emailVerifyPath = () => 'users/me/email/confirm/';
