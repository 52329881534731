import React, { FC } from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useRequestTerminationModal } from './controller';
import { Popup, WysiwygEditor } from '@components';
import { colors, typography } from '@theme';
import { IRequestTerminationParams, PopupTypeEnum } from '@interfaces';

interface ComponentProps {
  open: boolean;
  confirmCallback: (params: IRequestTerminationParams) => Promise<void>;
  onClose: () => void;
  drNumber: number;
  drType?: string;
  isMutating?: boolean;
  terminationType: string;
}

const RequestTerminationModal: FC<ComponentProps> = ({
  open,
  confirmCallback,
  onClose,
  drNumber,
  drType,
  isMutating,
  terminationType,
}) => {
  const {
    notes,
    reasons,
    reasonsList,
    handleConfirmClick,
    text,
    title,
    type,
    handleSelectChange,
    isSelectedChanged,
    buttonText,
    confirmDisabled,
    icon,
    reasonsText,
    commentText,
  } = useRequestTerminationModal({
    confirmCallback,
    terminationType,
    drNumber,
    drType,
  });

  const popupType = `${terminationType.toLowerCase()}__popup`;

  return (
    <Popup open={open} type={type} text={text} title={title} icon={icon}>
      <Stack sx={{ width: '100%' }} spacing={2}>
        <FormControl
          sx={{ mt: 0.5, width: '100%' }}
          required
          error={isSelectedChanged && reasons.length < 1}
        >
          <InputLabel
            id="reason-select-label"
            sx={{ ...typography.label, color: colors.text.medium }}
          >
            {reasonsText}
          </InputLabel>

          <Select
            multiple
            labelId="reason-select-label"
            id="reason-select"
            value={reasons}
            onChange={handleSelectChange}
            renderValue={(selected) => selected.join(', ')}
            input={<OutlinedInput label="Select reason(s)" />}
            data-cy={`${popupType}__reasons__select`}
          >
            {reasonsList?.map((item, index) => (
              <MenuItem
                key={index}
                value={item}
                data-cy={`${popupType}__reasons__select_item__index_${index}`}
              >
                <Checkbox checked={reasons.includes(item)} />
                <Typography variant="body2">{item}</Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Stack>
          <InputLabel
            id="comment-input-label"
            sx={{ ...typography.label, color: colors.text.medium, ml: 1.5 }}
          >
            {commentText}
          </InputLabel>
          <WysiwygEditor editField={notes} source={`${popupType}__comment`} />
        </Stack>

        <Stack sx={{ width: '100%' }}>
          <Stack direction="row" justifyContent="flex-end" spacing={2} sx={{ mt: '60px' }}>
            <Button variant="text" onClick={onClose}>
              Cancel
            </Button>
            <LoadingButton
              color={type === PopupTypeEnum.ERROR ? 'error' : 'primary'}
              loading={isMutating}
              onClick={handleConfirmClick}
              disabled={confirmDisabled}
              data-cy={`${popupType}__confirm__button`}
            >
              {buttonText}
            </LoadingButton>
          </Stack>
        </Stack>
      </Stack>
    </Popup>
  );
};

export default RequestTerminationModal;
