import React, { FC } from 'react';
import { CircularProgress } from '@mui/material';
import * as Controller from './controller';
import { HookState, PopupTypeEnum } from '@interfaces';
import { EditIcon } from '@svgAsComponents';
import { ConfirmationModal, IconButtonWithTooltip } from '@components';
import { getTooltipText } from '@utils';

const IconWithProjectPermission: FC<{
  openPopupCallback: () => void;
  permissionKey: string;
  projectId: string;
  isCurrentProjectArchived: boolean;
}> = ({ projectId, permissionKey, openPopupCallback, isCurrentProjectArchived }) => {
  const { closeErrorCallback, permissionNameClicked, isOpenErrorModal, handleClick, state } =
    Controller.useIconWithProjectPermission(projectId, permissionKey, openPopupCallback);

  return (
    <>
      <IconButtonWithTooltip
        onClick={handleClick}
        tooltipText={getTooltipText({ isCurrentProjectArchived })}
        disabled={isCurrentProjectArchived}
        sx={{ ml: 1 }}
      >
        {state === HookState.FETCHING && permissionNameClicked === permissionKey ? (
          <CircularProgress size={20} />
        ) : (
          <EditIcon size={20} />
        )}
      </IconButtonWithTooltip>
      <ConfirmationModal
        type={PopupTypeEnum.ERROR}
        title="Error"
        text={'You have not permission to perform this action.\nPlease contact support'}
        hideConfirmButton
        open={isOpenErrorModal}
        onClose={closeErrorCallback}
      />
    </>
  );
};

export default IconWithProjectPermission;
