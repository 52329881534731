/* eslint-disable no-console */
import React from 'react';
import {
  ErrorBoundary,
  AuthProvider,
  TransloaditProvider,
  ThemeConfig,
  NotistackProvider,
  hooks,
  isLocalhost,
  packageJsonVersion,
} from 'encirclelabs-shared';
import Router from './navigation/routes';

function App() {
  if (!isLocalhost) hooks.useCacheBoost({ version: packageJsonVersion });

  return (
    <ThemeConfig>
      <ErrorBoundary>
        <AuthProvider>
          <TransloaditProvider>
            <NotistackProvider>
              <Router />
            </NotistackProvider>
          </TransloaditProvider>
        </AuthProvider>
      </ErrorBoundary>
    </ThemeConfig>
  );
}

export default App;
