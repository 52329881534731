/* eslint-disable react/no-array-index-key */
import React, { PropsWithChildren } from 'react';
import { Popover, Grid } from '@mui/material';

import { colors } from '@theme';
import { ComponentProps } from './interface';

export default function PopoverTooltip({
  children,
  popoverContent,
  component = 'div',
}: PropsWithChildren<ComponentProps>) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Grid onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose} component={component}>
      {children}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        marginThreshold={0}
        sx={{
          pointerEvents: 'none',
          '& .MuiPaper-root': {
            maxWidth: '30rem',
            backgroundColor: colors.background.white,
            borderRadius: '4px',
            padding: '8px 16px',
            boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.15)',
          },
        }}
      >
        {popoverContent}
      </Popover>
    </Grid>
  );
}
