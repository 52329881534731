import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQueries, useQueryClient } from 'react-query';
import isInteger from 'lodash/isInteger';
import { debounceFunction, parsePathErrorDual } from '@utils';
import {
  DeleteBuildingParam,
  ErrorDual,
  IProjectProperty,
  MutationKeyEnum,
  PatchBuildingParam,
  PatchModelParam,
  QueryNamesEnums,
} from '@interfaces';
import {
  createProjectBuilding,
  deleteProjectBuilding,
  getProjectBuildings,
  patchProjectBuilding,
} from '@globalService';
import { useSafeSnackbar } from '@hooks';
import { useParams } from 'react-router-dom';
import { StringFieldModel, useStringFieldModel } from '@models';

export type ControllerInterface = {
  initColumns: string[];
  projectUnits: IProjectProperty[];
  addLineList: () => Promise<Response>;
  patchUnit: (args: PatchModelParam) => void;
  update: () => void;
  deleteItem: (id: string, isModelBuilding: boolean) => void;
  unitsNumber: StringFieldModel;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isLoading: boolean;
};

export const useUnitsTable = ({ isEditable }: { isEditable: boolean }): ControllerInterface => {
  const { projectId } = useParams();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSafeSnackbar();
  const [listItems, setListItems] = useState([]);

  const requestedDataQueries = useQueries([
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_BUILDING, { projectId }],
      queryFn: getProjectBuildings.bind(this, projectId),
    },
  ]);

  const projectBuildingsQuery = requestedDataQueries[0];

  const unitsNumber = useStringFieldModel({
    initValue: '0',
    validationRule: (value) => isInteger(+value) && +value >= projectBuildingsQuery.data?.count,
    validateOnChange: true,
  });

  const update = () => {
    queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_BUILDING, { projectId }]);
  };

  const addUnitMutation = useMutation<
    Response,
    Error,
    {
      projectId: string;
    }
  >(createProjectBuilding, {
    mutationKey: MutationKeyEnum.DRAW_REQUEST_ADD_ITEM,
    onSuccess: () => {
      update();
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const addLineList = useCallback(
    () => addUnitMutation.mutateAsync({ projectId }),
    [addUnitMutation],
  );

  const patchBuildingMutation = useMutation<Response, ErrorDual, PatchBuildingParam>(
    patchProjectBuilding,
    {
      mutationKey: MutationKeyEnum.MILESTONE_PATCH,
      onError: (error) => {
        enqueueSnackbar(parsePathErrorDual(error), { variant: 'error' });
      },
      onSuccess: () => {
        update();
      },
    },
  );

  const patchUnit = useCallback(
    ({ milestone, json }: PatchModelParam) => {
      patchBuildingMutation.mutate({
        project: projectId,
        building: milestone,
        json: json,
      });
    },
    [projectId],
  );

  const deleteBuildingMutation = useMutation<Response, ErrorDual, DeleteBuildingParam>(
    deleteProjectBuilding,
    {
      onSuccess: update,
      onError: (error) => {
        enqueueSnackbar(parsePathErrorDual(error), { variant: 'error' });
        update();
      },
    },
  );

  const initColumns = useMemo(
    () => ['productionBuildName', 'productionBuildDescription', 'productionBuildSquare'],
    [],
  );

  const createTableObject = (item, index) => ({
    ...item,
    activeToEdit: isEditable,
    canBeExpanded: false,
    isModelBuilding: false,
    isExpanded: false,
    index,
  });

  useEffect(() => {
    if (!projectBuildingsQuery.data?.results) return;

    setListItems(
      projectBuildingsQuery.data.results.map((item, index) => createTableObject(item, index)),
    );
  }, [projectBuildingsQuery.data?.results]);

  useEffect(() => {
    if (!projectBuildingsQuery.data?.count) return;
    unitsNumber.setValue(projectBuildingsQuery.data.count.toString());
  }, [projectBuildingsQuery.data?.count]);

  const deleteItem = useCallback(
    (id: string) => {
      deleteBuildingMutation.mutate({
        project: projectId,
        buildingId: id,
      });
    },
    [projectId],
  );

  const projectUnits = useMemo(
    () => listItems.map((item, index) => ({ ...item, index })),
    [listItems],
  );

  const handleInputChange = useCallback(
    debounceFunction(async (e: React.ChangeEvent<HTMLInputElement>) => {
      unitsNumber.handleChange(e);
      const newUnitsCount = +e.target.value - projectBuildingsQuery.data?.count;

      if (unitsNumber.validate(e.target.value) && newUnitsCount > 0) {
        const promises = Array.from({ length: newUnitsCount }, () =>
          addUnitMutation.mutateAsync({ projectId }),
        );

        // Split requests into chunks of 5 and send them
        const chunkSize = 5;
        for (let i = 0; i < promises.length; i += chunkSize) {
          const chunk = promises.slice(i, i + chunkSize);
          await Promise.all(chunk);
        }
      }
    }, 500),
    [addUnitMutation, projectBuildingsQuery.data?.count, unitsNumber],
  );

  return {
    initColumns,
    addLineList,
    projectUnits,
    patchUnit,
    update,
    deleteItem,
    unitsNumber,
    handleInputChange,
    isLoading: addUnitMutation.isLoading || projectBuildingsQuery.isLoading,
  };
};
