import React from 'react';
import { UserListColumnType } from './common';
import { Typography } from '@mui/material';
import { dateFormatter } from '@utils';

const lastOnline: UserListColumnType = {
  name: 'last_online',
  columnText: 'Last online',
  width: '20%',
  renderCell: ({ row }) => (
    <Typography variant="body2">
      {dateFormatter({ date: row.last_online, withTime: true })}
    </Typography>
  ),
};

export default lastOnline;
