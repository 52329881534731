import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Collapse, Stack, Typography } from '@mui/material';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';

import { StyledBox } from '@components';
import { ChevronUpIcon } from '@svgAsComponents';
import { ComponentCardProps } from './interface';
import snakeCase from 'lodash/snakeCase';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} sx={{ p: 0 }} />;
})(({ theme, expand }) => ({
  transform: expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: '1rem',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export const CollapsedCard: FC<PropsWithChildren<ComponentCardProps>> = ({
  title = '',
  isExpanded = false,
  children,
  expandOnMount,
  header,
  blockExpand,
  onClose,
  onOpen,
  fullHeight,
}) => {
  const [expanded, setExpanded] = useState<boolean>(isExpanded);

  const handleExpandClick = () => {
    setExpanded(!expanded);
    !expanded ? onOpen && onOpen() : onClose && onClose();
  };

  useEffect(() => {
    if (expandOnMount) handleExpandClick();
  }, []);

  return (
    <StyledBox sx={fullHeight && expanded ? { height: '100%' } : { px: 0 }}>
      <Stack direction="row" sx={{ alignItems: 'center', px: 2 }}>
        <Box flex={1}>
          {Boolean(title) && <Typography variant="h3">{title}</Typography>}
          {header}
        </Box>
        {!blockExpand && (
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
            data-cy={`${snakeCase(title)}__expand_card__icon`}
          >
            <ChevronUpIcon />
          </ExpandMore>
        )}
      </Stack>
      {children && (
        <Collapse in={expanded} sx={{ pt: 3, position: 'relative' }}>
          {children}
        </Collapse>
      )}
    </StyledBox>
  );
};
