import React, { useContext } from 'react';
import { Stack, TextField, Typography } from '@mui/material';

import { CurrencyInput, IconButtonWithTooltip } from '@components';
import { currencyFormatter, getTooltipText } from '@utils';
import { DeleteIcon } from '@svgAsComponents';
import { colors } from '@theme';
import { ILocalFee } from '@interfaces';
import { useIsMutating } from 'react-query';
import { SettingsContext } from '@context';

interface ComponentProps {
  fees: ILocalFee[];
  deleteFee: (index: number) => void;
  updateFee: (key: keyof ILocalFee, value: string | number, index: number) => void;
  canEdit: boolean;
  source: string;
}

const FeesV2 = ({ fees, deleteFee, updateFee, canEdit, source }: ComponentProps) => {
  const isMutating = useIsMutating();
  const { isCurrentProjectArchived } = useContext(SettingsContext);

  return (
    <>
      {fees?.map((fee, index) => (
        <Stack justifyContent="space-between" alignItems="center" key={index} direction="row">
          {fee.isNew ? (
            <TextField
              size="small"
              label="Name"
              sx={{ width: '250px' }}
              variant="outlined"
              value={fee.name}
              onChange={(e) => updateFee('name', e.target.value, index)}
              disabled={isCurrentProjectArchived || Boolean(isMutating)}
              data-cy={`${source}__fees__name__input__index_${index}`}
            />
          ) : (
            <Typography variant="body1" data-cy={`${source}__fees__name__value__index_${index}`}>
              {fee.name}
            </Typography>
          )}
          {canEdit ? (
            <Stack direction="row" spacing={1}>
              <TextField
                size="small"
                sx={{ width: '180px' }}
                variant="outlined"
                value={fee.amount}
                onChange={(e) => updateFee('amount', +e.target.value, index)}
                InputProps={{
                  inputComponent: CurrencyInput as any,
                }}
                disabled={isCurrentProjectArchived || Boolean(isMutating)}
                data-cy={`${source}__fees__amount__input__index_${index}`}
              />
              <IconButtonWithTooltip
                disabled={isCurrentProjectArchived || Boolean(isMutating)}
                tooltipText={getTooltipText({ isCurrentProjectArchived })}
                onClick={() => deleteFee(index)}
                data-cy={`${source}__fees__delete__button__index_${index}`}
                sx={{ pr: 0 }}
              >
                <DeleteIcon size={24} color={colors.icons.gray} />
              </IconButtonWithTooltip>
            </Stack>
          ) : (
            <Typography
              variant="body1SemiBold"
              data-cy={`${source}__fees__amount__value__index_${index}`}
            >
              {currencyFormatter(fee.amount)}
            </Typography>
          )}
        </Stack>
      ))}
    </>
  );
};

export default FeesV2;
