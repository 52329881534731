import { useState, useRef, useEffect } from 'react';

export const useCollapsibleWidget = ({
  widgetType,
  closeOnClickAway,
  initiallyOpened,
  shouldSaveState,
}: {
  widgetType: string;
  closeOnClickAway: boolean;
  initiallyOpened: boolean;
  shouldSaveState: boolean;
}) => {
  const parentStackRef = useRef(null);
  const collapsedFromLocalStore = localStorage.getItem(widgetType);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (JSON.parse(collapsedFromLocalStore) || (!collapsedFromLocalStore && initiallyOpened)) {
      setAnchorEl(parentStackRef.current);
    }
  }, [collapsedFromLocalStore, initiallyOpened]);
  const open = Boolean(anchorEl);

  const handleCollapseClick = () => {
    if (shouldSaveState) localStorage.setItem(widgetType, JSON.stringify(!anchorEl));
    setAnchorEl(anchorEl ? null : parentStackRef.current);
  };

  const handleClose = () => {
    closeOnClickAway && setAnchorEl(null);
    if (shouldSaveState && closeOnClickAway)
      localStorage.setItem(widgetType, JSON.stringify(false));
  };

  return {
    open,
    handleCollapseClick,
    handleClose,
    anchorEl,
    parentStackRef,
  };
};
