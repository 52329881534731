import React, { FC } from 'react';
import { DeleteIcon } from '@svgAsComponents';
import { ConfirmationModal } from '@components';
import { CSVPHBBudgetSectionsKeysEnum, PopupTypeEnum } from '@interfaces';
import { Checkbox, Stack, Typography } from '@mui/material';
import { useDeleteBudget } from './controller';

export const DeleteBudgetModal: FC<{
  onClose: () => void;
  deleteSections: (selectedKeys: CSVPHBBudgetSectionsKeysEnum[]) => void;
  isLoading: boolean;
}> = ({ onClose, deleteSections, isLoading }) => {
  const { budgetSectionsState, handleCheckboxChange } = useDeleteBudget();

  return (
    <ConfirmationModal
      open
      title="Delete budget section"
      text="Select the sections of the budget you want to delete."
      onClose={onClose}
      confirmCallback={() => {
        const selectedKeys = Object.keys(budgetSectionsState).filter(
          (key) => budgetSectionsState[key].isChecked,
        ) as CSVPHBBudgetSectionsKeysEnum[];
        deleteSections(selectedKeys);
      }}
      type={PopupTypeEnum.ERROR}
      isLoading={isLoading}
      confirmButtonLabel="Delete"
      icon={DeleteIcon}
    >
      {Object.keys(budgetSectionsState).map((key, index) => (
        <Stack key={key} direction="row" alignItems="center">
          <Checkbox
            checked={budgetSectionsState[key].isChecked}
            onChange={(_e, value) => handleCheckboxChange(key, value)}
            data-cy={`project__budget__delete__section__value__index_${index}`}
          />
          <Typography variant="body2">{budgetSectionsState[key].title}</Typography>
        </Stack>
      ))}
    </ConfirmationModal>
  );
};
