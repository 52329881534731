export const checklistsReasons = () => `checklists/items/reasons/`;

export const drawRequestItemChecklist = (
  projectId = 'project_pk',
  drawRequestId = 'draw_request_pk',
) => `projects/${projectId}/draw_requests/${drawRequestId}/checklists/`;

export const projectChecklist = (id = 'project_pk') => `projects/${id}/checklists/`;

export const projectPoliciesTemplate = (
  projectId = 'project_pk',
  checklistId = 'project_checklist_pk',
) => `projects/${projectId}/checklists/${checklistId}/`;

export const projectChecklistItem = (
  projectId = 'project_pk',
  checklistId = 'project_checklist_pk',
  checklistItemId = 'checklist_item_pk',
) => `projects/${projectId}/checklists/${checklistId}/items/${checklistItemId}/`;

export const projectChecklistItems = (
  projectId = 'project_pk',
  checklistId = 'project_checklist_pk',
) => `projects/${projectId}/checklists/${checklistId}/items/`;

export const drawRequestChecklistItem = (
  projectId = 'project_pk',
  drawRequestId = 'draw_request_pk',
  checklistId = 'draw_request_checklist_pk',
  checklistItemId = 'checklist_item_pk',
) =>
  `projects/${projectId}/draw_requests/${drawRequestId}/checklists/${checklistId}/items/${checklistItemId}/`;

export const drawRequestChecklistItemDocument = (
  projectId = 'project_pk',
  drawRequestId = 'draw_request_pk',
  checklistId = 'draw_request_checklist_pk',
  checklistItemId = 'checklist_item_pk',
  documentId = 'documentId_pk',
) =>
  `projects/${projectId}/draw_requests/${drawRequestId}/checklists/${checklistId}/items/${checklistItemId}/documents/${documentId}/`;

export const drawRequestChecklistItems = (
  projectId = 'project_pk',
  drawRequestId = 'draw_request_pk',
  checklistId = 'draw_request_checklist_pk',
) => `projects/${projectId}/draw_requests/${drawRequestId}/checklists/${checklistId}/items/`;

export const drawRequestPolicies = (
  projectId = 'project_pk',
  drawRequestId = 'draw_request_pk',
  checklistId = 'draw_request_checklist_pk',
) => `projects/${projectId}/draw_requests/${drawRequestId}/checklists/${checklistId}/`;

export const requestChecklistTemplates = (projectId = 'project_pk') =>
  `projects/${projectId}/requests_checklists_templates/`;

export const requestChecklistTemplate = (
  projectId = 'project_pk',
  checklistId = 'project_checklist_pk',
) => `projects/${projectId}/requests_checklists_templates/${checklistId}/`;
