import React from 'react';
import { Skeleton, Tooltip } from '@mui/material';
import truncate from 'lodash/truncate';
import { MAX_TEXT_LENGTH } from '@constants';

const lineItemColumn = ({ isLoading, source }) => ({
  Header: 'Line item',
  accessor: 'line_item_name',
  disableFilters: true,
  disableSortBy: true,
  Cell: ({ cell: { value }, row }) => {
    if (isLoading) return <Skeleton />;
    const lineItemNames = value?.join(', ') || '';
    return (
      <Tooltip title={lineItemNames?.length > MAX_TEXT_LENGTH ? lineItemNames : ''}>
        <span data-cy={`${source}__line_items__value__index_${row.index}`}>
          {truncate(lineItemNames, { length: MAX_TEXT_LENGTH })}
        </span>
      </Tooltip>
    );
  },
});

export default lineItemColumn;
