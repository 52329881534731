import React, { FC, useContext, useState } from 'react';
import { Box, Divider, Stack } from '@mui/material';

import {
  ApprovalsSummaryBody,
  IconButtonWithTooltip,
  LabelAndValue,
  SwitchApproverPopup,
} from '@components';
import { ChangeIcon, EditIcon } from '@svgAsComponents';
import { ApproveActionEnum, IDrawRequest } from '@interfaces';
import * as Controller from './controller';
import { getTooltipText } from '@utils';
import { SettingsContext } from '@context';

const ApprovalTeams: FC<{
  projectId: string;
  request: IDrawRequest;
  approveActionType: ApproveActionEnum;
}> = ({ request, approveActionType, projectId }) => {
  const { isCurrentProjectArchived } = useContext(SettingsContext);
  const [approverPopupOpen, setApproverPopupOpen] = useState<boolean>(false);
  const { companyTeamsWithoutCurrentUserTeam } = Controller.useApprovalTeams(request);

  return (
    <Stack spacing={1}>
      <Divider />
      <Box pt={2} />
      <ApprovalsSummaryBody request={request} projectId={projectId} />
      {Boolean(companyTeamsWithoutCurrentUserTeam?.length) && (
        <LabelAndValue
          label="Reviewer team"
          text={request?.current_reviewer_team?.name}
          valueIcon={
            approveActionType !== ApproveActionEnum.SEND_FOR_APPROVAL ? (
              <IconButtonWithTooltip
                onClick={() => setApproverPopupOpen(true)}
                tooltipText={getTooltipText({ isCurrentProjectArchived })}
                disabled={isCurrentProjectArchived}
                sx={{ p: 0 }}
              >
                <EditIcon />
              </IconButtonWithTooltip>
            ) : null
          }
        />
      )}
      {approverPopupOpen && (
        <SwitchApproverPopup
          open={approverPopupOpen}
          request={request}
          onClose={() => setApproverPopupOpen(false)}
          projectId={projectId}
          icon={ChangeIcon}
          source="request__action_popup"
        />
      )}
    </Stack>
  );
};

export default ApprovalTeams;
