import React, { useState, useEffect, FC, JSXElementConstructor } from 'react';
import { useIsMutating, useIsFetching } from 'react-query';
import { styled, alpha } from '@mui/material/styles';
import { PopperProps } from '@mui/material/Popper';
import {
  Box,
  ButtonBase,
  ClickAwayListener,
  Grid,
  InputBase,
  Popper,
  Stack,
  Typography,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Autocomplete, {
  AutocompleteChangeReason,
  AutocompleteCloseReason,
  autocompleteClasses,
} from '@mui/material/Autocomplete';
import { colors } from '@theme';
import { ComponentProps } from './interface';
import { CheckboxIconChecked, CheckboxIconDefault } from '@svgAsComponents';
import { QueryNamesEnums } from '@interfaces';

interface PopperComponentProps {
  anchorEl?: null | Element | ((element: Element) => Element);
  disablePortal?: boolean;
  open: boolean;
}

const StyledAutocompletePopper = styled('div')(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: 'none',
    margin: 0,
    color: 'inherit',
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: colors.white,
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      alignItems: 'flex-start',
      padding: 8,
      borderBottom: `1px solid  #eaecef'}`,
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      '&[data-focus="true"], &[data-focus="true"][aria-selected="true"]': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative',
  },
}));

function PopperComponent(props: PopperComponentProps) {
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
}

const StyledPopper = styled(Popper)(() => ({
  border: `1px solid ${colors.neutral.lighter}`,
  boxShadow: `0 8px 24px ${alpha(colors.secondary, 0.25)}`,
  borderRadius: 4,
  width: 360,
  zIndex: 1300,
  backgroundColor: colors.white,
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
  padding: 10,
  width: '100%',
  borderBottom: `1px solid ${colors.neutral.lighter}`,
  '& input': {
    borderRadius: 4,
    backgroundColor: colors.white,
    padding: 8,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    border: `1px solid ${colors.neutral.lighter}`,
    fontSize: 14,
    '&:focus': {
      boxShadow: `0px 0px 0px 3px ${alpha(theme.palette.secondary.main, 0.5)}`,
      borderColor: theme.palette.secondary.main,
    },
  },
}));

const Button = styled(ButtonBase)(() => ({
  width: '100%',
}));

interface LabelType {
  name: string;
  id?: string;
  color?: string;
  description?: string;
}

const FilterButton: FC<ComponentProps> = ({
  labels,
  title,
  valueName,
  updateFilters,
  initialValue,
  isOptionEqualToValue = (option, value) => option.id === value?.id,
  label,
  dataTestName,
  fixedWidth,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [value, setValue] = useState<LabelType[]>([]);
  const [pendingValue, setPendingValue] = useState<LabelType[]>([]);

  const isBlockedByFetching = Boolean(useIsFetching([QueryNamesEnums.GET_UI_SETTINGS]));
  const isBlockedByMutating = Boolean(useIsMutating());

  const isOptionsDisabled = isBlockedByMutating || isBlockedByFetching;

  useEffect(() => {
    if (!initialValue) return;
    setValue(initialValue);
    setPendingValue(initialValue);
  }, [initialValue, labels]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setPendingValue(value);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setValue(pendingValue);
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  function handleChange(
    event: React.SyntheticEvent<Element, Event>,
    newValue: LabelType[],
    reason: AutocompleteChangeReason,
  ) {
    if (
      event.type === 'keydown' &&
      (event as React.KeyboardEvent).key === 'Backspace' &&
      reason === 'removeOption'
    ) {
      return;
    }
    setPendingValue(newValue);
    updateFilters({ [valueName]: newValue });
  }

  const renderOption = (props, option, { selected }) => (
    <li {...props}>
      <Stack flexDirection="row" alignItems="center" sx={{ minHeight: 24 }}>
        <Stack sx={{ height: 18, mr: '12px' }}>
          {selected ? <CheckboxIconChecked size={18} /> : <CheckboxIconDefault size={18} />}
        </Stack>
        <Stack>
          <Typography variant="body3" sx={{ whiteSpace: 'normal' }}>
            {option?.[label] || option?.name || option}
          </Typography>
        </Stack>
      </Stack>
    </li>
  );

  const renderFilterValue = () => (
    <>
      <Typography
        variant="body3SemiBold"
        color={colors.text.medium}
        sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        {pendingValue?.length ? stringToShow : 'All'}
      </Typography>
      <KeyboardArrowDownIcon />
    </>
  );

  const open = Boolean(anchorEl);
  const id = open ? `${title}-label` : undefined;

  const stringToShow = pendingValue?.map((el) => el[label] || el.name || el).join(', ');

  if (!labels?.length) return null;

  return (
    <Grid container alignItems="center">
      <Box sx={{ maxWidth: fixedWidth || '320px', minWidth: fixedWidth || '120px' }}>
        <Button onClick={handleClick} data-cy={dataTestName}>
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{
              width: '100%',
              height: '32px',
              backgroundColor: colors.neutral.lightest,
              borderRadius: '2px',
              p: 1,
              '& svg': { width: 16, height: 16 },
              color: colors.text.medium,
              ...(fixedWidth && { justifyContent: 'space-between' }),
            }}
          >
            <Typography variant="body3" color={colors.text.medium} sx={{ whiteSpace: 'nowrap' }}>
              {`${title}:`}
            </Typography>
            {(fixedWidth && (
              <Stack direction="row" sx={{ width: '108px', justifyContent: 'flex-end' }}>
                {renderFilterValue()}
              </Stack>
            )) ||
              renderFilterValue()}
          </Stack>
        </Button>
      </Box>
      <StyledPopper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        nonce={undefined}
        onResize={undefined}
        onResizeCapture={undefined}
        sx={{ width: fixedWidth || '320px' }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Autocomplete
              open
              multiple
              onClose={(
                event: React.SyntheticEvent<Element, Event>,
                reason: AutocompleteCloseReason,
              ) => {
                if (reason === 'escape') handleClose();
              }}
              value={pendingValue}
              onChange={handleChange}
              disableCloseOnSelect
              PopperComponent={PopperComponent as JSXElementConstructor<PopperProps>}
              renderTags={() => null}
              noOptionsText="No labels"
              renderOption={renderOption}
              options={labels}
              getOptionLabel={(option) => option?.[label] || option?.name || option}
              renderInput={(params) => (
                <StyledInput
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  autoFocus
                  placeholder={`Find ${title.toLowerCase()}`}
                />
              )}
              isOptionEqualToValue={isOptionEqualToValue}
              getOptionDisabled={() => isOptionsDisabled}
            />
          </div>
        </ClickAwayListener>
      </StyledPopper>
    </Grid>
  );
};

export default FilterButton;
