import React, { Dispatch, SetStateAction } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  Stack,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
  Tooltip,
  Table,
  TableBody,
  TableContainer,
} from '@mui/material';

import { LoadingSkeleton, ServiceMessage, StyledBox } from '@components';
import * as Controller from './controller';
import { HookState, ITeam } from '@interfaces';
import { TeamRow } from './components';
import { colors } from '@theme';

interface ListComponentsProps {
  state: HookState;
  teamsInApprovalFlow: ITeam[];
  teamsExcludedFromApproval: ITeam[];
  setTeams: Dispatch<SetStateAction<ITeam[]>>;
  handleBulkUpdateTeam: () => void;
  hasCompanyTeamsEditPermission: boolean;
  handleTeamApprovalFlow: (value: boolean, index: number) => void;
  isApprovalFlowMutating: boolean;
}

const ListBlock = ({
  state,
  teamsInApprovalFlow,
  teamsExcludedFromApproval,
  setTeams,
  handleBulkUpdateTeam,
  hasCompanyTeamsEditPermission,
  handleTeamApprovalFlow,
  isApprovalFlowMutating,
}: ListComponentsProps) => {
  switch (state) {
    case HookState.FETCHING: {
      return <LoadingSkeleton type="overviewBlock" />;
    }

    case HookState.ERROR: {
      return <ServiceMessage text="company teams" />;
    }

    case HookState.SUCCESS: {
      return (
        <TableContainer>
          <Table>
            <TableBody>
              {teamsInApprovalFlow?.map((item, index) => (
                <TeamRow
                  team={item}
                  key={item.id || index}
                  index={index}
                  setItems={setTeams}
                  handleBulkUpdateTeam={handleBulkUpdateTeam}
                  hasCompanyTeamsEditPermission={hasCompanyTeamsEditPermission}
                  handleTeamApprovalFlow={handleTeamApprovalFlow}
                  listLength={teamsInApprovalFlow?.length}
                  isApprovalFlowMutating={isApprovalFlowMutating}
                />
              ))}
              {teamsExcludedFromApproval.map((item, index) => (
                <TeamRow
                  team={item}
                  key={item.id || index}
                  index={index}
                  setItems={setTeams}
                  handleBulkUpdateTeam={handleBulkUpdateTeam}
                  hasCompanyTeamsEditPermission={hasCompanyTeamsEditPermission}
                  handleTeamApprovalFlow={handleTeamApprovalFlow}
                  excludedFromApproval
                  isApprovalFlowMutating={isApprovalFlowMutating}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    }

    default:
      return null;
  }
};

const CompanyWorkflow = () => {
  const {
    state,
    teamsInApprovalFlow,
    teamsExcludedFromApproval,
    setTeamsInApprovalFlow,
    handleBulkUpdateTeam,
    reviewsQuantity,
    fundingApproveRequired,
    isSettingsMutating,
    handleQuantityClick,
    requeredReviewsValuesArray,
    handleFundingReviewClick,
    hasCompanyTeamsEditPermission,
    handleTeamApprovalFlow,
    isApprovalFlowMutating,
  } = Controller.useCompanyWorkflow();

  const disabledTooltipText = hasCompanyTeamsEditPermission
    ? ''
    : "You don't have permission to edit company workflow";

  return (
    <DndProvider backend={HTML5Backend}>
      <Stack spacing={2}>
        <StyledBox>
          <Stack>
            <Typography variant="h3" mb={2}>
              Approval flow
            </Typography>
            <Typography variant="body1">
              Here you can set required user approvals for approval flow, sort & remove teams from
              approval flow.
            </Typography>
          </Stack>

          <Stack direction="row" alignItems="center" mt={3} mb={5.5}>
            <Typography variant="body1SemiBold" mr={2.5}>
              Required number of approvals
            </Typography>
            <Tooltip title={disabledTooltipText} placement="right">
              <Stack direction="row" alignItems="center" spacing={1}>
                {requeredReviewsValuesArray.map((item) => (
                  <Box
                    key={item}
                    sx={{
                      width: '32px',
                      height: '32px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor:
                        item === reviewsQuantity
                          ? colors.main.primary.main
                          : colors.neutral.lightest,

                      opacity: isSettingsMutating ? 0.5 : 1,
                      cursor: hasCompanyTeamsEditPermission ? 'pointer' : 'unset',
                    }}
                    onClick={hasCompanyTeamsEditPermission ? handleQuantityClick(item) : null}
                    data-cy={`company_teams__workflow__required_reviews__${item}_button_${
                      item === reviewsQuantity ? 'active' : 'inactive'
                    }}`}
                  >
                    <Typography
                      variant="body3SemiBold"
                      sx={{
                        color: item === reviewsQuantity ? colors.neutral.white : colors.text.medium,
                      }}
                    >
                      {item}
                    </Typography>
                  </Box>
                ))}
              </Stack>
            </Tooltip>
            <Tooltip title={disabledTooltipText} placement="right">
              <FormControlLabel
                sx={{ ml: 12 }}
                control={
                  <Checkbox
                    checked={fundingApproveRequired}
                    onChange={(_e, value) => {
                      handleFundingReviewClick(value);
                    }}
                    disabled={isSettingsMutating || !hasCompanyTeamsEditPermission}
                    data-cy="company_teams__workflow__funding_approve__checkbox"
                  />
                }
                label={<Typography variant="body2">Funding approve is required</Typography>}
              />
            </Tooltip>
          </Stack>

          <ListBlock
            teamsInApprovalFlow={teamsInApprovalFlow}
            teamsExcludedFromApproval={teamsExcludedFromApproval}
            state={state}
            setTeams={setTeamsInApprovalFlow}
            handleBulkUpdateTeam={handleBulkUpdateTeam}
            hasCompanyTeamsEditPermission={hasCompanyTeamsEditPermission}
            handleTeamApprovalFlow={handleTeamApprovalFlow}
            isApprovalFlowMutating={isApprovalFlowMutating}
          />
        </StyledBox>
      </Stack>
    </DndProvider>
  );
};

export default CompanyWorkflow;
