import React, { FC } from 'react';
import ReactDOM from 'react-dom';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { colors } from '@theme';
import { documentsTypeMap } from '@constants';
import { ComponentProps } from './interface';
import { BetaChip, DocumentSummary } from '@components';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const PDFViewerNew: FC<ComponentProps> = ({ pdfFile, close, withoutPortal }) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  if (!pdfFile) return null;

  const getFileName = (file) =>
    file?.name || documentsTypeMap[file?.document_type] || file?.document_type || `PDF File`;

  const modalBody = () => (
    <Stack
      alignItems="center"
      position="fixed"
      sx={{
        overflow: 'hidden',
        top: 0,
        height: '100vh',
        width: '100%',
        zIndex: 9999,
        left: 0,
        backgroundColor: colors.background.gray,
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          width: '100%',
          backgroundColor: colors.background.white,
          padding: '1.5rem',
          borderBottom: `1px solid ${colors.background.gray}`,
        }}
      >
        <Typography variant="h2">{getFileName(pdfFile)}</Typography>
        <CloseIcon sx={{ cursor: 'pointer', ml: 'auto' }} onClick={close} />
      </Stack>
      <Stack
        direction="row"
        sx={{
          width: '100%',
          backgroundColor: colors.background.white,
          height: 'calc(100vh)',
        }}
      >
        <Stack
          sx={{
            width: pdfFile?.has_smart_summary ? 'calc(100vw - 720px)' : '100%',
            padding: '1.5rem',
            height: '90%',
          }}
        >
          {pdfModal()}
        </Stack>
        {pdfFile?.has_smart_summary && (
          <Stack sx={{ width: '720px', padding: '1.5rem', height: '90%' }}>
            <Stack direction="row" alignContent="center" spacing={1}>
              <Typography variant="h3">SmartSummary</Typography>
              <BetaChip dataTestName="pdf__viewer__documents_summary__beta__chip" />
            </Stack>
            <DocumentSummary row={pdfFile} source="pdf__viewer" canBeProcessed />
          </Stack>
        )}
      </Stack>
    </Stack>
  );

  const pdfModal = () => (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
      <Viewer fileUrl={pdfFile.file_url} plugins={[defaultLayoutPluginInstance]} />
    </Worker>
  );

  return withoutPortal ? (
    <>{pdfModal()}</>
  ) : (
    <>{ReactDOM.createPortal(modalBody(), document.body)}</>
  );
};

export default PDFViewerNew;
