import React from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import Chip, { chipClasses, ChipProps } from '@mui/material/Chip';
import { styled } from '@mui/system';
import { colors } from '@theme';
import { DeleteIcon, PlusIcon } from '@svgAsComponents';
import { IconButtonWithTooltip } from '@components';

interface Props {
  items: string[];
  reportTag?: boolean;
  setReportTag?: (reportTag: boolean) => void;
  showReportTag?: boolean;
  source: string;
}

const Container = styled(Grid)({
  flexWrap: 'wrap',
});

interface StyledChipProps extends ChipProps {
  inactive?: boolean;
}

const Tag = styled(({ inactive, style, ...otherProps }: StyledChipProps) => (
  <Chip {...otherProps} />
))(({ inactive, style = {} }) => ({
  border: 0,
  borderRadius: '2px',
  backgroundColor: inactive ? colors.neutral.lighter : colors.status.information.lighter,
  maxWidth: '100%',
  [`& .${chipClasses.label}`]: {
    padding: '4px 8px',
    letterSpacing: 'unset',
  },
  ...style,
}));

const ReportTag = ({ reportTag, setReportTag, dataTestNameReportTag }) => (
  <Grid item sx={{ maxWidth: '100%' }} key="Report" data-cy={dataTestNameReportTag}>
    <Stack direction="row">
      <Tag
        label={
          <Typography
            variant="labelSemiBold"
            sx={{
              whiteSpace: 'normal',
              color: reportTag ? colors.status.information.medium : colors.text.medium,
            }}
          >
            Report
          </Typography>
        }
        style={{ height: '100%', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
        inactive={!reportTag}
      />
      <IconButtonWithTooltip
        color="secondary"
        onClick={() => setReportTag(!reportTag)}
        sx={{ p: 0.5, borderTopRightRadius: '2px', borderBottomRightRadius: '2px' }}
        tooltipText={
          reportTag ? 'Remove this comment from the report' : 'Include this comment to the report'
        }
      >
        {reportTag ? (
          <DeleteIcon color={colors.status.information.medium} size={16} />
        ) : (
          <PlusIcon color={colors.status.information.medium} size={16} />
        )}
      </IconButtonWithTooltip>
    </Stack>
  </Grid>
);

const Tags = ({ items, reportTag, setReportTag, showReportTag, source }: Props) => {
  return (
    <Container container alignItems="center" spacing={1}>
      {items.map((item) => (
        <Grid item sx={{ maxWidth: '100%' }} key={item}>
          <Tag
            label={
              <Typography
                variant="labelSemiBold"
                sx={{
                  whiteSpace: 'normal',
                  color: colors.status.information.medium,
                }}
                data-cy={`__tag_${item}`}
              >
                {item}
              </Typography>
            }
            key={item}
            style={{ height: '100%' }}
          />
        </Grid>
      ))}

      {showReportTag && (
        <ReportTag
          reportTag={reportTag}
          setReportTag={setReportTag}
          dataTestNameReportTag={`${source}__report_tag__button`}
        />
      )}
    </Container>
  );
};

export default Tags;
