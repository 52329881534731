import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const ColumnChooserIcon = ({ size = 20, color = colors.icons.gray }: ComponentProps) => (
  <svg
    width={size}
    height={(size / 20) * 21}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.11639 0C1.01182 0 0.116394 0.895431 0.116394 2V18C0.116394 19.1046 1.01182 20 2.11639 20H7.11639C8.22096 20 9.11639 19.1046 9.11639 18V2C9.11639 0.895432 8.22096 0 7.11639 0H2.11639ZM2.11639 2H7.11639V18H2.11639V2ZM13.1164 0C12.0118 0 11.1164 0.895431 11.1164 2V18C11.1164 19.1046 12.0118 20 13.1164 20H18.1164C19.221 20 20.1164 19.1046 20.1164 18V2C20.1164 0.895432 19.221 0 18.1164 0H13.1164ZM13.1164 2H18.1164V18H13.1164V2Z"
      fill={color}
    />
  </svg>
);

export default ColumnChooserIcon;
