import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';

import { LoadingSkeleton } from '@components';
import { IDrawRequest } from '@interfaces';
import * as Controller from './controller';
import { dateFormatter } from '@utils';

export const ApprovalsSummaryBody: FC<{
  request: IDrawRequest;
  projectId: string;
}> = ({ request, projectId }) => {
  const { approvalReviews, fundReviews, isLoading } = Controller.useApprovalsSummary(
    request,
    projectId,
  );

  const { reviews_required_quantity, customer_approve_reviews_quantity, funding_approve_required } =
    request || {};

  if (!request || isLoading) return <LoadingSkeleton />;
  return (
    <Stack spacing={2}>
      <Stack spacing={1}>
        <Typography variant="body2SemiBold">{`Approvals (${customer_approve_reviews_quantity}/${reviews_required_quantity})`}</Typography>
        {approvalReviews?.map((review) => (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
            key={review?.created_by?.id}
          >
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
              <Typography variant="body3">{review?.created_by_team?.name}</Typography>
            </Stack>
            <Typography variant="label" sx={{ ml: 2 }}>{`${
              review?.created_by?.full_name
            }, ${dateFormatter({ date: review?.created_at })}`}</Typography>
          </Stack>
        ))}
      </Stack>

      {Boolean(fundReviews?.length) && (
        <Stack spacing={1}>
          <Typography variant="body2SemiBold">Funded by</Typography>
          {Boolean(funding_approve_required) &&
            fundReviews.map((review) => (
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
                key={review?.created_by?.id}
              >
                <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                  <Typography variant="body3">{review?.created_by_team?.name}</Typography>
                </Stack>
                <Typography variant="label" sx={{ ml: 2 }}>{`${
                  review?.created_by?.full_name
                }, ${dateFormatter({ date: review?.created_at })}`}</Typography>
              </Stack>
            ))}
        </Stack>
      )}
    </Stack>
  );
};
