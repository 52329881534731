import React, { useState } from 'react';
import { SxProps, Theme } from '@mui/material/styles';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';

import { DateFieldModel } from '@models';

interface DatePickerInterface {
  field: DateFieldModel;
  label: string;
  validationText?: string;
  minDate?: Date;
  maxDate?: Date;
  sx?: SxProps<Theme>;
  inputStyles?: SxProps<Theme>;
  validateOnLoad?: boolean;
  disabled?: boolean;
  dataTestName?: string;
  required?: boolean;
  onChange?: (e: any) => void;
  inputProps?: object;
}
const CustomDatePickerInput = ({
  field,
  label,
  validationText = 'Invalid date',
  inputStyles,
  validateOnLoad = true,
  required,
  inputProps,
  onChange,
  ...props
}: DatePickerInterface) => {
  const [isTouched, setTouched] = useState(validateOnLoad);
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        label={label}
        format="MM/dd/yyyy"
        value={field.value || null}
        onChange={onChange || field.handleChangePicker}
        slotProps={{
          textField: {
            fullWidth: true,
            variant: 'outlined',
            helperText:
              field.isValid || !isTouched
                ? ''
                : field.value
                  ? field.validationError || validationText
                  : `${label} is required`,
            error: !field.isValid && isTouched,
            size: 'small',
            sx: inputStyles,
            onBlur: () => setTouched(true),
            required,
            inputProps: {
              'data-cy': props.dataTestName,
              ...inputProps,
            },
          },
        }}
        {...props}
      />
    </LocalizationProvider>
  );
};

export default CustomDatePickerInput;
