import React, { FC, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import { StringFieldModel } from '@models';
import { Box, styled } from '@mui/material';
import { colors } from '@theme';
import 'react-quill/dist/quill.snow.css';

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link'],
    ['clean'],
  ],
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
];

export const Wrapper = styled(Box)(({ theme }) => ({
  ' .ql-toolbar.ql-snow': {
    border: 'none',
    padding: theme.spacing(0.5, 0),
  },
  ' .ql-container.ql-snow': {
    border: 'none',
  },
  ' .ql-editor': {
    padding: theme.spacing(1),
    minHeight: '100px',
    borderRadius: '2px',
    backgroundColor: colors.neutral.lightest,
  },
  ' .ql-snow .ql-tooltip': {
    left: '0 !important',
    top: '0 !important',
  },
  ' .ql-snow.ql-toolbar button': {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '2px',
    backgroundColor: 'white',
    border: `0.5px solid ${colors.neutral.lighter}`,
    marginRight: '2px',
  },
  ' svg': {
    width: '18px',
    height: '18px',
  },
  ' .ql-hidden': {
    visibility: 'hidden',
  },
}));

const WysiwygEditor: FC<{
  editField: StringFieldModel;
  source: string;
  placeholder?: string;
  readOnly?: boolean;
}> = ({ editField, source, placeholder = 'Write a comment', readOnly = false }) => {
  const quillRef = useRef<ReactQuill | null>(null);

  useEffect(() => {
    if (quillRef.current) {
      const quillEditor = quillRef.current?.getEditor();
      if (quillEditor) {
        const editorInput = quillEditor.root;
        editorInput.setAttribute('data-cy', `${source}__input`);
        // Add data-cy attributes to the formatting buttons
        document.querySelector('.ql-bold')?.setAttribute('data-cy', `${source}__bold__button`);
        document.querySelector('.ql-italic')?.setAttribute('data-cy', `${source}__italic__button`);
        document
          .querySelector('.ql-underline')
          ?.setAttribute('data-cy', `${source}__underline__button`);
        document.querySelector('.ql-strike')?.setAttribute('data-cy', `${source}__strike__button`);
        document.querySelector('.ql-list')?.setAttribute('data-cy', `${source}__list__button`);
        document.querySelector('.ql-link')?.setAttribute('data-cy', `${source}__link__button`);
        document.querySelector('.ql-clean')?.setAttribute('data-cy', `${source}__clean__button`);
      }
    }
  }, []);

  return (
    <Wrapper>
      <ReactQuill
        ref={quillRef}
        placeholder={placeholder}
        modules={modules}
        formats={formats}
        onChange={editField.setValue}
        value={editField.value}
        readOnly={readOnly}
      />
    </Wrapper>
  );
};

export default WysiwygEditor;
