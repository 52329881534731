import React, { FC } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { CloseIcon } from '@svgAsComponents';
import { colors } from '@theme';
import { TableItem } from '../TableItem';
import { useChooseNamingModal } from './ChooseBudgetViewlController';

export const ChooseBudgetViewModal: FC<{
  onClose: () => void;
  open: boolean;
  isCreatedByModels: boolean;
}> = ({ open, onClose, isCreatedByModels }) => {
  const { budgetViewOptions, budgetView, setBudgetView, updateBudgetView } = useChooseNamingModal({
    isCreatedByModels,
    onClose,
  });

  return (
    <Dialog
      open={open}
      maxWidth="lg"
      sx={{
        '& .MuiPaper-root': {
          borderRadius: { md: '4px', xs: 0 },
          minWidth: { lg: 1000, xs: '100%' },
          minHeight: { md: 'auto', xs: '100%' },
        },
      }}
    >
      <Stack sx={{ p: 4, flex: 1 }}>
        <Stack justifyContent="space-between" direction="row" alignItems="center" mb={3}>
          <Typography variant="h2">Budget view</Typography>
          <IconButton
            onClick={onClose}
            data-cy="project__budget__create__budget_view__modal__close__button"
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <DialogContent sx={{ justifyContent: 'center', padding: 0 }}>
          <Typography variant="body1">
            Choose the default view you want to see & fill the budget with numbers.
          </Typography>
          <RadioGroup
            aria-label="inputType"
            name="inputType"
            value={budgetView}
            onChange={(event) => {
              setBudgetView(event.target.value);
            }}
            sx={{
              px: 0.5,
              width: '100%',
              flexDirection: 'row',
              alignItems: 'flex-end',
              justifyContent: isCreatedByModels ? 'space-between' : 'space-around',
            }}
          >
            {budgetViewOptions.map((option, index) => (
              <Stack sx={{ pt: 3 }} key={option.label}>
                <FormControlLabel
                  key={option.label}
                  value={option.label}
                  control={<Radio />}
                  label={
                    <Typography variant="body1SemiBold" color={colors.text.heading}>
                      {option.label}
                    </Typography>
                  }
                  sx={{ pb: 1 }}
                  data-cy={`project__budget__create__budget_view__modal__value__index_${index}`}
                />
                <Stack padding={3} border={`1px solid ${colors.neutral.light}`}>
                  <TableItem name={option.mainLevel} isExpanded />
                  <TableItem name={option.nestedLevel + ' 1'} isMainLevel={false} />
                  <TableItem name={option.nestedLevel + ' 2'} isMainLevel={false} />
                  <TableItem name={option.nestedLevel + ' 3'} isMainLevel={false} />
                </Stack>
              </Stack>
            ))}
          </RadioGroup>
        </DialogContent>
        <DialogActions sx={{ p: 0, flex: 1, alignItems: 'flex-end', mt: 5 }}>
          <Button
            onClick={updateBudgetView}
            data-cy="project__budget__create__budget_view__modal__confirm__button"
          >
            Confirm
          </Button>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};
