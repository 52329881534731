import React, { useContext, useState } from 'react';
import { StyledBox } from '@components';
import { IconButton, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { colors } from '@theme';
import { DotIcon, EditIcon, EmailIcon, PhoneIcon, UserIcon } from '@svgAsComponents';
import { AuthContext } from '@context';
import { phoneFormatter } from '@utils';
import { PersonalInfoEditPopup } from './components';

const UserTab = () => {
  const { user } = useContext(AuthContext);
  const smMediaQuery = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const [isOpenPersonalInfoPopup, setIsOpenPersonalInfoPopup] = useState(false);

  return (
    <StyledBox sx={{ flex: 1 }}>
      <Stack sx={{ flexDirection: { sm: 'row', xs: 'column' } }}>
        <Stack alignItems="center" justifyContent="center">
          <IconButton
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '120px',
              width: '120px',
              backgroundColor: colors.background.gray,
              borderRadius: '4px',
            }}
          >
            <UserIcon color={colors.icons.gray} />
          </IconButton>
        </Stack>

        <Stack
          sx={{
            flexDirection: 'column',
            justifyContent: 'space-around',
            flex: 1,
            ml: { sm: 3, xs: 0 },
            mt: { sm: 0, xs: 3 },
          }}
        >
          <Stack
            sx={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Typography variant="h1">{`${user.first_name} ${user.last_name}`}</Typography>
            <Stack>
              <IconButton onClick={() => setIsOpenPersonalInfoPopup(true)}>
                <EditIcon size={20} />
              </IconButton>
            </Stack>
          </Stack>
          <Stack
            sx={{
              flexDirection: { sm: 'row', xs: 'column' },
              alignItems: { sm: 'center', xs: 'flex-start' },
              justifyContent: { sm: 'flex-start', xs: 'center' },
            }}
          >
            {user.phone && (
              <Stack flexDirection="row" alignItems="center" sx={{ mt: { sm: 0, xs: 3 } }}>
                <PhoneIcon />
                <Typography variant="body1" sx={{ ml: 0.75, mr: 2 }}>
                  {phoneFormatter(user.phone)}
                </Typography>
                {smMediaQuery && <DotIcon />}
              </Stack>
            )}
            <Stack
              flexDirection="row"
              alignItems="center"
              sx={{ ml: { sm: 2, xs: 0 }, mt: { sm: 0, xs: 1.5 } }}
            >
              <EmailIcon />
              <Typography variant="body1" sx={{ ml: 0.75, overflowWrap: 'anywhere' }}>
                {user.email}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      {isOpenPersonalInfoPopup && (
        <PersonalInfoEditPopup
          open={isOpenPersonalInfoPopup}
          onClose={() => setIsOpenPersonalInfoPopup(false)}
        />
      )}
    </StyledBox>
  );
};

export default UserTab;
