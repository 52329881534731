import React, { FC } from 'react';
import { Stack } from '@mui/material';
import { colors } from '@theme';
import { Filter, MilestoneListWithPatch } from '@components';
import { useBudgetTable } from './controller';
import { DeleteBudgetModal } from '../Modals/DeleteBudgetModal/';

const BudgetTable: FC = () => {
  const {
    initColumns,
    onExpandClick,
    groupByOptions,
    filterOptions,
    milestones,
    filterValue,
    handleFilterClick,
    groupByValue,
    handleGroupByClick,
    isLoading,
    menuItems,
    isDeleteBudgetPopupOpen,
    setIsDeleteBudgetPopupOpen,
    deleteBudget,
    isDeleting,
    totals,
    patchMsGroup,
  } = useBudgetTable();

  return (
    <Stack
      sx={{ backgroundColor: colors.background.white, pt: 1, borderRadius: '4px', flexGrow: 1 }}
    >
      <MilestoneListWithPatch
        withColumnIndication
        initColumns={initColumns}
        milestones={milestones}
        key="listWithEditModels"
        onExpandTable={onExpandClick}
        menuItems={menuItems}
        totals={totals}
        headerLeftPart={[
          {
            Component: (
              <Filter
                filterLabel="Show"
                onChangeCallback={handleFilterClick}
                options={filterOptions}
                filterValue={filterValue}
                source="project__budget__line_items_table__filter"
              />
            ),
          },
          {
            Component: (
              <Stack sx={{ ml: 1.5 }}>
                <Filter
                  filterLabel="Group by"
                  onChangeCallback={handleGroupByClick}
                  options={groupByOptions}
                  filterValue={groupByValue}
                  source="project__budget__line_items_table__group_by"
                />
              </Stack>
            ),
          },
        ]}
        source="project__budget__line_items_table"
        isLoading={isLoading}
        lockSecondColumn
        patchMilestone={patchMsGroup}
      />
      {isDeleteBudgetPopupOpen && (
        <DeleteBudgetModal
          onClose={() => setIsDeleteBudgetPopupOpen(false)}
          deleteSections={deleteBudget}
          isLoading={isDeleting}
        />
      )}
    </Stack>
  );
};
export default BudgetTable;
