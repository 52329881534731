export const lineItemColumnsToCSV = [
  'adjustments_rate',
  'approved_adjustments',
  'original_estimate',
  'approved_amount',
  'approved_amount_cumulative',
  'approved_amount_relative',
  'balance_to_finish',
  'balance_to_finish_rate',
  'cost_type',
  'current_adjustments',
  'name',
  'inspector_allowance',
  'inspector_allowance_incremental',
  'inspector_allowance_rate',
  'inspector_allowance_rate_incremental',
  'lender_allowance_rate',
  'original_construction_budget',
  'prefunding_cost',
  'previous_approved_amount_cumulative',
  'previous_changes',
  'previous_changes_rate',
  'previous_lender_allowance_rate',
  'requested_adjustments',
  'requested_adjustments_rate',
  'requested_amount',
  'requested_amount_relative',
  'requested_revised_estimate',
  'retainage_approved_amount_holdback',
  'retainage_balance_to_date',
  'retainage_rate',
  'retainage_release_approved',
  'retainage_release_requested',
  'retainage_requested_amount_holdback',
  'revised_construction_budget',
  'revised_estimate',
  'revised_milestone_amount',
  'variance_to_lender_allowance',
  'variance_to_lender_allowance_rate',
];

export const lineItemColumnsMap = {
  approved_adjustments: 'approved_adjustments',
  cost_type: 'cost_type.display',
};

export const milestoneTotalsMap = {
  previous_changes: 'previous_estimate_changes',
  requested_adjustments: 'requested_budget_change',
};

export const excludeRequestColumns = ['id', 'type'];

export const requestColumnToCSVMap = {
  current_reviewer_team: 'current_reviewer_team.name',
  description: 'name',
  'project.status': 'projectStatus',
  status: 'requestStatus',
};

export const PHBColumnsForCSV = ['original_estimate', 'prefunding_cost'];
