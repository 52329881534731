import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { useCommentTypes } from './controller';
import { typography } from '@theme';

interface ComponentProps {
  onChange: (values: string[]) => void;
  dataTestName: string;
}

const FilterMultiSelect = ({ onChange, dataTestName }: ComponentProps) => {
  const { types, values, selectedValues, handleSelectChange } = useCommentTypes(onChange);

  if (!values?.length) return null;

  return (
    <Autocomplete
      id="comment-filters"
      multiple
      size="small"
      options={values}
      value={selectedValues}
      getOptionLabel={(option) => types[option]}
      onChange={handleSelectChange}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            '& .MuiInputBase-root': {
              height: '32px',
              borderRadius: '2px',
            },
            '& .MuiInputLabel-root': {
              ...typography.body3,
              top: '-10px',
              lineHeight: '32px',
              '&.Mui-focused': { top: '-4px' },
            },
            '& .MuiChip-root': {
              top: '-5px',
            },
          }}
          label="Comment type"
          data-cy={dataTestName}
        />
      )}
    />
  );
};

export default FilterMultiSelect;
