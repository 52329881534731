import React, { FC } from 'react';
import { Stack } from '@mui/material';
import { colors } from '@theme';
import { Filter, MilestoneListWithPatch } from '@components';
import { TableKeyEnum } from '@interfaces';
import { useInspectionTable } from './controller';

const InspectionTable: FC<{
  showRequestedAmount?: boolean;
  tableKey?: TableKeyEnum;
  containerRef: React.RefObject<HTMLDivElement>;
}> = ({ showRequestedAmount, tableKey = TableKeyEnum.INSPECTION_RESULTS, containerRef }) => {
  const {
    initColumns,
    onExpandClick,
    groupByOptions,
    filterOptions,
    milestones,
    filterValue,
    handleFilterClick,
    groupByValue,
    handleGroupByClick,
    isLoading,
    patchInspectionMSgroup,
    totals,
  } = useInspectionTable(showRequestedAmount);

  return (
    <Stack
      sx={{ backgroundColor: colors.background.white, pt: 1, borderRadius: '4px', flexGrow: 1 }}
    >
      <MilestoneListWithPatch
        patchMilestone={
          tableKey === TableKeyEnum.INSPECTION_ENTER_RESULTS ? patchInspectionMSgroup : undefined
        }
        withColumnIndication
        tableKey={tableKey}
        initColumns={initColumns}
        milestones={milestones}
        key="listWithEditModels"
        onExpandTable={onExpandClick}
        totals={totals}
        headerLeftPart={[
          {
            Component: (
              <Filter
                filterLabel="Show"
                onChangeCallback={handleFilterClick}
                options={filterOptions}
                filterValue={filterValue}
                source="inspection_results__line_items_table__filter"
              />
            ),
          },
          {
            Component: (
              <Stack sx={{ ml: 1.5 }}>
                <Filter
                  filterLabel="Group by"
                  onChangeCallback={handleGroupByClick}
                  options={groupByOptions}
                  filterValue={groupByValue}
                  source="inspection_results__line_items_table__group_by"
                />
              </Stack>
            ),
          },
        ]}
        source="inspection_results__line_items_table"
        isLoading={isLoading}
        lockSecondColumn
        containerRef={containerRef}
      />
    </Stack>
  );
};
export default InspectionTable;
