import { useCallback, useContext, useReducer, useState } from 'react';
import useWebSocket from 'react-use-websocket';
import { AuthContext } from '@context';
import { config } from '@config';
import { handleWebSocketMessage } from './webSocketHelpers';
import { useEventInvalidationHandlers } from './invalidationHandlers';
import { IWSMessageData } from '@interfaces';

const messageReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_MESSAGE':
      return [...state, action.payload];
    default:
      return state;
  }
};

export const useWebSocketsEvents = () => {
  const { user } = useContext(AuthContext);
  const [messages, dispatch] = useReducer(messageReducer, []);
  const [socketUrl] = useState(`${config.wssUrl}/${user.id}/`);
  const { invalidateQueriesByEventType } = useEventInvalidationHandlers();

  const invalidateData = useCallback(
    (params: IWSMessageData) => {
      const handler = invalidateQueriesByEventType[params.event_type];
      if (handler) {
        handler(params);
      }
    },
    [invalidateQueriesByEventType],
  );

  useWebSocket(socketUrl, {
    onOpen: () => console.log('WebSocket connection established.'),
    onMessage: (message) => handleWebSocketMessage(message, user, dispatch, invalidateData),
    onClose: () => console.log('WebSocket connection closed.'),
    shouldReconnect: () => true,
    reconnectAttempts: 10,
    reconnectInterval: (attemptNumber) => Math.min(5000 * Math.pow(2, attemptNumber), 300000),
  });

  return { messages };
};
