import { Button, Stack } from '@mui/material';
import React, { FC } from 'react';
import { LoadingButton } from '@mui/lab';

import {
  ButtonWithTooltip,
  CustomMultiAutocompleteWithTags,
  InternationalPhoneField,
  CustomTextField,
  Popup,
} from '@components';
import * as Controller from './controller';
import { AddUserIcon } from '@svgAsComponents';

interface ComponponentProps {
  borrowerCompanyId?: string;
  title?: string;
  isProjectStatusCreated?: boolean;
  size?: 'small' | 'medium' | 'large';
}

const InviteUsersButton: FC<ComponponentProps> = ({
  borrowerCompanyId,
  title = 'Invite user',
  isProjectStatusCreated,
  size = 'medium',
}) => {
  const {
    selectedTeams,
    setSelectedTeams,
    email,
    firstName,
    lastName,
    phone,
    handleAddCompanyUser,
    isSubmitting,
    teams,
    optionsLoading,
    isOpened,
    setOpened,
    onCloseCallback,
    inviteDisabled,
    isEditUsersEnabled,
  } = Controller.useAddCompanyUser({ borrowerCompanyId });

  if (!isEditUsersEnabled) return null;

  return (
    <>
      <ButtonWithTooltip
        onClick={() => setOpened(true)}
        dataTestName={
          borrowerCompanyId
            ? 'borrower_company_users__invite_team_member_button'
            : 'company_users__invite_team_member_button'
        }
        disabled={inviteDisabled}
        tooltipText={
          inviteDisabled ? 'You can’t invite the user because of the absence of team.' : ''
        }
        size={size}
      >
        {title}
      </ButtonWithTooltip>

      {isOpened && (
        <Popup open={isOpened} icon={AddUserIcon} maxWidth="sm" title={title}>
          <Stack sx={{ width: '100%', justifyContent: 'space-between', flex: 1 }}>
            <Stack spacing={2}>
              <CustomTextField
                field={email}
                label="Email"
                required
                validationText="Please enter a valid email"
                inputProps={{
                  'data-cy': 'company_users__invite_user_email_input',
                }}
              />
              <CustomMultiAutocompleteWithTags
                label="Team"
                options={teams}
                field={selectedTeams}
                setField={setSelectedTeams}
                optionsLoading={optionsLoading}
                inputProps={{
                  'data-cy': 'company_users__invite_user_team_input',
                }}
              />
              <CustomTextField
                field={firstName}
                label="First name"
                inputProps={{
                  'data-cy': 'company_users__invite_user_first_name_input',
                }}
              />
              <CustomTextField
                field={lastName}
                label="Last name"
                inputProps={{
                  'data-cy': 'company_users__invite_user_last_name_input',
                }}
              />
              <InternationalPhoneField
                field={phone}
                label="Phone"
                inputProps={{
                  'data-cy': 'company_users__invite_user_phone_input',
                }}
              />
            </Stack>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              sx={{ mt: 4 }}
              spacing={2}
            >
              <Button
                variant="text"
                onClick={onCloseCallback}
                data-cy="company_users__invite_user_cancel_button"
              >
                Cancel
              </Button>
              <LoadingButton
                loading={isSubmitting}
                onClick={() => handleAddCompanyUser()}
                data-cy="company_users__invite_user_invite_button"
              >
                {borrowerCompanyId && isProjectStatusCreated ? 'Add' : 'Invite'}
              </LoadingButton>
            </Stack>
          </Stack>
        </Popup>
      )}
    </>
  );
};

export default InviteUsersButton;
