export default {
  isDev: process.env.NODE_ENV === 'development',
  url: process.env.PUBLIC_URL,
  api: process.env.REACT_APP_API_ROOT_URL,
  mockApi: 'https://de4e52d0-9e9a-44e1-bddf-e82e66c0e8cc.mock.pstmn.io',
  sentryDsn: 'https://0fe7f4f4bdd94012b27ea06d42a8848b@o406777.ingest.sentry.io/5461616',
  sentryEnv: process.env.REACT_APP_SENTRY_ENV,
  gtmId: process.env.REACT_APP_GTM_ID,
  launchDarklyClientSideID: process.env.REACT_APP_LD_CLIENT_SIDE_ID,
  datadogClientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
  datadogApplicationID: process.env.REACT_APP_DATADOG_APP_ID,
  datadogEnv: process.env.REACT_APP_DATADOG_ENV,
  datadogSite: 'us3.datadoghq.com',
};
