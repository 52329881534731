import React, { FC, useCallback, useContext, useMemo } from 'react';
import { Skeleton, Typography } from '@mui/material';
import { TableContext } from '../controller';
import { useDebounceInput, useStringFieldModel } from '@models';
import { ColumnLabel, CommonRowType, HeaderText, MilestoneListColumnType } from './common';
import {
  approximationEqual,
  checkIsAdmin,
  checkIsInvestor,
  checkIsLender,
  currencyFormatter,
  getMilestoneGroupsTagsIds,
  getTeamRole,
  isCostTypeContingency,
  useBlockerCell,
  useBlockerFooter,
} from '@utils';
import { TextInputWithTooltip } from '@components';
import { AuthContext } from '@context';
import {
  ColumnV2Width,
  ColumnWidth,
  ContingencyDescriptionEnum,
  IMilestoneTotal,
} from '@interfaces';

const Cell: FC<{ row: CommonRowType }> = ({ row }) => {
  const { apiUpdate, resetMutation, getLineItemError } = useContext(TableContext);
  const error = getLineItemError(row.id, 'approved_adjustments');
  const tags = useMemo(() => getMilestoneGroupsTagsIds(row.tags), [row.tags]);

  const approved_adjustments = useStringFieldModel({
    initError: error,
    initValue: (row['approved_adjustments'] || '').toString(),
    withProgressCheck: true,
    blockNavigationKey: 'approved_adjustments',
  });

  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const isBlocked = useBlockerCell({
    milestoneId: row.id,
    key: 'approved_adjustments',
    tags,
  });

  const isEditable = useMemo(
    () =>
      row.activeToEdit &&
      (checkIsLender(teamRole) || checkIsInvestor(teamRole) || checkIsAdmin(teamRole)),
    [teamRole, row],
  );

  const effect = useCallback(
    (props) =>
      apiUpdate({
        milestoneId: row.id,
        value: approved_adjustments.floatValue,
        needToCompare: false,
        isEqualInit: approximationEqual(row.approved_adjustments, approved_adjustments.floatValue),
        name: 'approved_adjustments',
        ...(row.tags && { milestoneTags: tags }),
        ...props,
      }),
    [approved_adjustments.floatValue, row.approved_adjustments, tags],
  );

  const { onBlur, onChange } = useDebounceInput({
    effect,
    handleChange: approved_adjustments.handleChange,
    resetter: () => resetMutation({ milestone: row.id, json: { approved_adjustments: 0 } }),
    afterEffect: () => approved_adjustments.changeNavigationBlockContext(false),
  });

  if (isBlocked) return <Skeleton width={'100%'} />;

  return isEditable ? (
    <TextInputWithTooltip
      value={approved_adjustments.value}
      onChange={onChange}
      error={Boolean(error)}
      type="currency"
      onBlur={onBlur}
      tooltipText={
        row.disabled?.reason ||
        (isCostTypeContingency(row.cost_type) && ContingencyDescriptionEnum.COLUMN_DESCRIPTION)
      }
      disabled={row.disabled?.value}
      errorTip={error}
      dataTestName={`milestone_row_approved_changes_index_${row?.project_milestone?.index}`}
    />
  ) : (
    <Typography variant="body3">{currencyFormatter(row.approved_adjustments, '-')}</Typography>
  );
};

const Footer: FC<{ row: IMilestoneTotal }> = ({ row }) => {
  const isBlocked = useBlockerFooter();

  if (isBlocked) return <Skeleton width={'100%'} />;
  return (
    <Typography variant="body3SemiBold" data-cy="milestone_row_approved_changes_total">
      {currencyFormatter(row.approved_budget_change || 0)}
    </Typography>
  );
};

const approvedAdjustments: MilestoneListColumnType = {
  name: 'approved_adjustments',
  columnText: 'Approved +/- change ($)',
  width: ColumnWidth.INPUT_MONEY,
  isEditable: true,
  renderCell: ({ row }) => <Cell row={row} />,
  Footer: (row) => <Footer row={row} />,
  minWidth: (activeToEdit = false) =>
    activeToEdit ? ColumnV2Width.INPUT : ColumnV2Width.NUMBER_MIN,
  maxWidth: (activeToEdit = false) =>
    activeToEdit ? ColumnV2Width.INPUT : ColumnV2Width.NUMBER_MAX,
  Header: (
    <HeaderText tooltipText="Approved changes to revised scheduled value for this request">
      <ColumnLabel>Approved</ColumnLabel>
      <ColumnLabel> +/- change ($)</ColumnLabel>
    </HeaderText>
  ),
};

export default approvedAdjustments;
